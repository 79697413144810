<template>
  <div
    class="InputSelectDropdown"
    ref="dropdown"
    @keydown="handleDropdownKeydown">

    <!-- Search Field -->
    <div
      class="__search"
      v-if="isSearchable"
      key="input-select-search">

      <InputText
        class="__search__field"
        ref="search"
        v-model="searchValue"
        icon="search"
        :placeholder="searchPlaceholder"
        :is-clearable="true"
        :is-placeholder-persistent="false"
        :is-no-border="true"
        :input-style="{
          height: '34px',
          border: 'none !important',
          background: '#F6F6F6',
        }"
        @focus="handleSearchFocus"
      />
    </div>

    <!-- No Results -->
    <div
      class="__noresults"
      v-show="!computedOptions.length">
      No results for "<b>{{ searchValue }}</b>"
    </div>

    <!-- Options -->
    <InputSelectOption
      v-for="(option, optionIndex) in computedOptions"
      :option="option"
      :is-selected="isOptionSelected(option)"
      :ref="setOptionRef"
      :key="option.value"
      @select="handleInput(option)"
      @focus="handleOptionFocus(optionIndex)">
      <template
        v-slot:default>
        <slot
          name="option"
          :option="option"
          :is-selected="isOptionSelected(option)">
        </slot>
      </template>
    </InputSelectOption>
  </div>
</template>


<script>
import InputText from '@/components/InputText.vue';
import InputSelectOption from '@/components/InputSelectOption.vue';

export default {
  name: 'InputSelectDropdown',

  components: {
    InputText,
    InputSelectOption,
  },

  data() {
    return {
      searchValue: '',
      optionRefs: [],
      focusedOptionIndex: -1,
    };
  },

  props: {
    modelValue: {
      type: [String, Array, Boolean],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    computedOptions() {
      if (!this.isSearchable) return this.options;

      const searchValueLowercased = this.searchValue.toLowerCase();

      return this.options.filter(({ label, value }) => label
        .toLowerCase()
        .includes(searchValueLowercased)
      || value
        .toLowerCase()
        .includes(searchValueLowercased));
    },
  },

  beforeUpdate() {
    this.optionRefs = [];
  },

  methods: {
    handleDropdownKeydown(e) {
      switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        this.focusOption(this.focusedOptionIndex - 1);
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.focusOption(this.focusedOptionIndex + 1);
        break;
      // case 'Escape':
      //   e.preventDefault();
      //   this.closeDropdown();
      //   break;
      default:
        break;
      }
    },

    handleInput(option) {
      this.$emit('update:modelValue', option);
    },

    isOptionSelected(option) {
      const modelValueType = typeof this.modelValue;

      if (modelValueType === 'string') {
        if (!this.modelValue) return !option.value;
        return this.modelValue === option.value;
      }

      if (modelValueType === 'object') {
        if (!this.modelValue.length) return !option.value;
        return this.modelValue.some((selectedValue) => selectedValue === option.value);
      }

      if (modelValueType === 'boolean') {
        return this.modelValue === option.value;
      }

      return false;
    },

    handleSearchFocus() {
      this.focusedOptionIndex = -1;
    },

    handleOptionFocus(optionIndex) {
      this.focusedOptionIndex = optionIndex;
    },

    focusOption(optionIndex) {
      let clampedIndex = optionIndex;

      const optionsLength = this.computedOptions.length - 1;

      if (optionIndex < 0) {
        clampedIndex = optionsLength;
      }
      if (optionIndex > optionsLength) {
        clampedIndex = 0;
      }

      this.optionRefs[clampedIndex].focus();
    },

    focusSearch() {
      this.$refs.search.focus();
    },

    setOptionRef(el) {
      if (el) this.optionRefs.push(el);
    },

    reset() {
      this.searchValue = '';
      this.focusedOptionIndex = -1;
      this.$el.scrollTop = 0;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.InputSelectDropdown {
  max-height: 250px;
  padding-bottom: 8px;
  border: 1px solid $border-color;
  border-top: none;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  overflow-x: hidden;
  overflow-y: auto;
}


// Search
.__search {
  padding: 8px;
}
.__noresults {
  padding: 8px 16px 11px 16px;
  color: $mute;
  font-size: 14px;
}


// Option
.__option {
  display: block;
  width: 100%;
  padding: 8px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
