<template>
  <div
    class="InputPhoneIntl">
    <InputText
      type="tel"
      :placeholder="placeholder"
      :is-placeholder-persistent="isPlaceholderPersistent"
      :is-invalid="isInvalid"
      :model-value="modelValue"
      :error-message="!isDropped ? errorMessage : null"
      ref="input"
      @update:model-value="handleInput">
      <template
        v-slot:leading>
        <button
          class="__flag"
          type="button"
          @click.prevent="toggleDropdown">
          <span
            class="fflag ff-round ff-md"
            :class="`fflag-${country}`">
          </span>
        </button>
      </template>
    </InputText>

    <InputSelectDropdown
      v-show="isDropped"
      ref="dropdown"
      search-placeholder="Search countries"
      :model-value="country"
      :options="countries"
      :is-searchable="true"
      @update:model-value="handleCountrySelect">
      <template
        v-slot:option="{ option, isSelected }">
        <div
          class="__label">
          <span
            class="fflag ff-round ff-md"
            :class="`fflag-${option.iso2}`">
          </span>
          <span
            class="ml-onehalf">
            {{ option.label }}
          </span>
        </div>

        <IconCheckmark
          :size="20"
          :is-checked="isSelected"
        />
      </template>
    </InputSelectDropdown>
  </div>
</template>


<script>
import usePhone from '@/use/phone';
import countries from '@/data/countries';
import InputText from '@/components/InputText.vue';
import InputSelectDropdown from '@/components/InputSelectDropdown.vue';
import IconCheckmark from '@/components/IconCheckmark.vue';

export default {
  name: 'InputPhoneIntl',

  components: {
    InputText,
    InputSelectDropdown,
    IconCheckmark,
  },

  setup() {
    const {
      parseIntlPhoneNumber,
    } = usePhone();

    return {
      parseIntlPhoneNumber,
    };
  },

  data() {
    return {
      countries,
      isDropped: false,
    };
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    isPlaceholderPersistent: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },

  emits: [
    'update:modelValue',
    'update:country',
  ],

  methods: {
    handleInput(value) {
      const {
        iso2,
        formatted,
      } = this.parseIntlPhoneNumber(value, this.country);

      // @BUG not validating after country changed?
      if (iso2 !== this.country) {
        this.$emit('update:country', iso2);
      }

      // @NOTE Why null? Reference -> https://stackoverflow.com/q/68496743/3000726
      this.$emit('update:modelValue', null);
      this.$nextTick(() => {
        this.$emit('update:modelValue', formatted || '');
      });
    },

    handleCountrySelect(country) {
      this.$emit('update:country', country.iso2);
      this.handleInput(this.modelValue);
      this.closeDropdown();
    },

    toggleDropdown() {
      this[`${this.isDropped ? 'close' : 'open'}Dropdown`]();
    },

    openDropdown() {
      this.isDropped = true;

      setTimeout(() => {
        window.addEventListener('keyup', this.handleWindowKeyup);
        window.addEventListener('click', this.handleClickOutside);

        this.$refs.dropdown.focusSearch();
      }, 200);
    },

    closeDropdown(refocusField = true) {
      this.isDropped = false;
      this.$refs.dropdown.reset();
      window.removeEventListener('keyup', this.handleWindowKeyup);
      window.removeEventListener('click', this.handleClickOutside);
      if (refocusField) this.$refs.input.focus();
    },

    handleWindowKeyup(e) {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.closeDropdown();
      }
    },

    handleClickOutside(e) {
      const isInElement = e.target === this.$refs.dropdown.$el
        || this.$refs.dropdown.$el.contains(e.target);

      if (!isInElement) this.closeDropdown(false);
    },
  },
};
</script>


<style src="@/styles/_countries.scss" lang="scss"></style>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.__flag:focus {
  .fflag {
    box-sizing: border-box;
    border: 1px solid $dark;
  }
}

.__label {
  padding-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
