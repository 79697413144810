import { parsePhoneNumberFromString } from 'libphonenumber-js';
import COUNTRIES from '@/data/countries';


// Methods
function findCountriesByDialCode(dialCode) {
  return COUNTRIES
    .filter((c) => c.dialCode === dialCode)
    .map((c) => c.iso2);
}

function parseIntlPhoneNumber(number, countryCode) {
  const valuePersisted = number.match(/[()\-+0-9\s]*/g).join('');

  const isManualCountryCode = valuePersisted?.startsWith('+') || false;

  const parsedNumber = isManualCountryCode
    ? parsePhoneNumberFromString(valuePersisted)
    : parsePhoneNumberFromString(valuePersisted, countryCode);

  const parseMode = 'NATIONAL';
  // @NOTE enable below for international formats (i.e. +code-number)
  // const parseMode = (!parsedNumber || !isManualCountryCode)
  //   ? 'NATIONAL'
  //   : 'INTERNATIONAL';

  const phoneObj = parsedNumber || {};

  let iso2 = countryCode;

  if (isManualCountryCode && phoneObj.countryCallingCode) {
    const manualPossibleCountries = findCountriesByDialCode(phoneObj.countryCallingCode);
    const isPassedCountryPossible = manualPossibleCountries.includes(countryCode);

    if (!isPassedCountryPossible) {
      iso2 = [...manualPossibleCountries].shift();
    }
  }

  const isValid = phoneObj.isValid?.();

  const formatted = isValid
    ? phoneObj.format?.(parseMode)
    : valuePersisted;

  return {
    iso2,
    isValid,
    formatted,
  };
}


// Exports
export default function usePhone() {
  return {
    parseIntlPhoneNumber,
  };
}
